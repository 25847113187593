<template>
  <div>
    <h2 class="mb-0 h3">{{ data.title }}</h2>
    <h4 class="h5">{{ data.date }}</h4>
    <div class="card">
      <div class="card-body" v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      data: {}
    }
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get(`/api/v1/moderator/announcements/${this.$route.params.id}`).then(response => {
        this.data = response.data.data
        this.$loader.stop()
      })
    }
  }
}
</script>